<template lang="html">
  <section class="map">
    <ol-map :loadTilesWhileAnimating="true" :loadTilesWhileInteracting="true" style="height: 300px">
      <ol-view ref="view" :center="center" :rotation="rotation" :zoom="zoom" :projection="projection" />

      <ol-tile-layer>
        <ol-source-osm />
      </ol-tile-layer>
      <ol-vector-layer>
        <ol-source-vector>
          <ol-feature>
            <ol-geom-point :coordinates="coordinate"></ol-geom-point>
            <ol-style>
              <ol-style-icon src="/img/icons/icon-map-fill.svg" scale="0.8"></ol-style-icon>
            </ol-style>
          </ol-feature>
        </ol-source-vector>

      </ol-vector-layer>
    </ol-map>
  </section>
</template>

<script lang="js">
  import {
    ref
  } from 'vue'
  export default {
    setup() {
      const center = ref([-3.6909825310569184, 40.42243795949289])
      const projection = ref('EPSG:4326')
      const zoom = ref(16)
      const coordinate = ref([-3.6909932596429584, 40.42247879796273])

      return {
        center,
        projection,
        zoom,
        coordinate
      }
    },
    name: 'map',
    props: [],
    mounted() {

    },
    data() {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
  }
</script>

<style scoped lang="scss">
  .map {}
</style>