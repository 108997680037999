<template lang="html">
  <section class="contact">
    <div class="container">
      <div @click="goToBack()" class="btn-back" title="Volver a la página anterior">Volver</div>
      <div class="container-search-box" id="formulario">
        <template v-if="formSend == true">
          <p class="msg-succes">Gracias por contactar con nosotros. <br>Pronto nos pondremos en contacto con usted.</p>
        </template>
        <template v-else>
          <h1 class="title-cat-big">Contacto</h1>
          <p class="subtitle">Puede entrar en contacto con cermi.es diario a través de este formulario:</p>
          <Form :validation-schema="schema" v-slot="{ errors }" @submit="onSubmit" ref="formObject">
            <div class="column">
              <label for="asunto">Asunto</label>
              <Field name="asunto" class="input-styled keyword" placeholder="Título del mensaje" :class="{ error: errors.asunto }" v-model="data.asunto" />
              <span class="mss-error">{{ errors.asunto }}</span>
            </div>
            <div class="column">
              <label for="email">Correo electrónico</label>
              <Field name="email" class="input-styled email" placeholder="Introduzca su dirección de correo electrónico" :class="{ error: errors.email }" v-model="data.email" />
              <span class="mss-error">{{ errors.email }}</span>
            </div>
            <div class="column">
              <label for="telefono">Teléfono</label>
              <Field name="telefono" class="input-styled phone" placeholder="Introduzca su teléfono" :class="{ error: errors.telefono }" v-model="data.telefono" />
              <span class="mss-error">{{ errors.telefono }}</span>
            </div>
            <div class="column">
              <label for="mensaje">Mensaje</label>
              <Field as="textarea" name="mensaje" class="input-styled message" placeholder="Escriba aquí...." :class="{ error: errors.mensaje }" v-model="data.mensaje" />
              <!-- <span class="mss-error">{{ errors.phone }}</span> -->
            </div>
            <div class="checkbox-styled">
              <Field id="aceptacion_legal" name="aceptacion_legal" type="checkbox" v-model="data.aceptacion_legal" :value="true" :unchecked-value="false" />
              <label for="aceptacion_legal">
                He leído y acepto el
                <router-link :to="{ name: 'privacy' }" title="Ir a página de Privacidad">Régimen de uso de datos y
                  privacidad</router-link>

              </label>
              <span class="mss-error"><br>{{ errors.aceptacion_legal }}</span>
            </div>
            <div class="row-center w-100">
              <button type="button" class="btn-white" @click="onSubmit">Contacto</button>
            </div>
          </Form>
        </template>

      </div>
      <p class="subtitle">También puede entrar en contacto a través del correo:</p>
      <div class="grid-two">
        <div class="grid">
          <p class="subtitle">Carta a la Dirección</p>
          <a title="Mandar un email" href="mailto:correoabierto@cermi.es" class="link-mail">correoabierto@cermi.es</a>
        </div>
        <div class="grid">
          <p class="subtitle">Redacción</p>
          <a title="Mandar un email" href="mailto:cermi@cermi.es" class="link-mail">redacciondigital@cermi.es</a>
        </div>
      </div>
      <div class="container-map">
        <div class="info-box">
          <p class="title-dest">Dónde estamos</p>
          <p class="link-location">Calle Recoletos, 1 Bajo 28001 Madrid, España</p>
          <a title="Llamar a 91 360 16 78" href="tel:913601678" class="link-phone">91 360 16 78</a>

        </div>
        <div class="content-map">
          <mapOpenLayer></mapOpenLayer>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="js">
  import mapOpenLayer from "@/components/map.vue";

  import {
    mapGetters,
    mapActions,
    mapMutations
  } from 'vuex';
  import {
    Form,
    Field,
    defineRule,
    configure,
    ErrorMessage
  } from "vee-validate";

  export default {
    name: 'contact',
    props: [],
    mounted() {

    },
    components: {
      Field,
      Form,
      mapOpenLayer,

    },
    data() {
      const schema = {
        asunto: 'required',
        email: 'required|email',
        telefono: 'required|digits:9',
        aceptacion_legal: 'required'
      };
      return {
        loading: false,
        formSend: false,
        schema,
        data: {
          asunto: '',
          email: '',
          telefono: '',
          mensaje: '',
          aceptacion_legal: '',
          recaptcha: ''
        }
      };
    },
    methods: {
      ...mapActions({
        formContact: 'form/formContact'
      }),
      goToBack() {
        this.$router.back()
      },
      onSubmit() {
        // console.log('envio')
        this.loading = true
        this.$recaptcha("contact").then((token) => {
          //console.log(token)
          this.data.recaptcha = token
          this.formContact(this.data).then((response) => {
            if (document.getElementById('formulario')) {
              var offsetTop = document.getElementById('formulario').offsetTop - 150;
              setTimeout(function () {
                scroll({
                  behavior: "smooth",
                  top: offsetTop,
                });
              }, 200);
            }
            this.formSend = true
            this.loading = false
          })
        });

      },
    },
    computed: {

    },
    watch: {
      "loading"() {
        if (this.loading) {
          let loader = this.$loading.show({
            container: this.$refs.loadingContainer,
            zIndex: 99,
          });
          setTimeout(() => {
            loader.hide()
          }, 1000)
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../styles/colours.scss";

  .contact {
    .msg-succes {
      font-family: "Frank Ruhl Libre", serif;
      font-size: 20px;
      line-height: 1.1em;
      margin-bottom: 15px;
      font-weight: 700;
      color: white;
      text-align: center;
      background: #0069B4;
      padding: 20px;
      line-height: 1.5em;
      margin: 30px 0;
    }

    h1 {
      text-align: center;
      border-top: 0;
    }

    .link-aviso {
      margin-bottom: 15px;
      display: inline-block;
    }

    .subtitle {
      color: #000000;
      text-align: center;
    }

    .btn-white {
      margin: 40px 0 60px 0;
    }

    .grid-two {
      .grid {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: $blueLight;
        padding: 40px;
        border-radius: 13px;
      }
    }

    .container-map {
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;

      .info-box {
        width: 300px;
        padding-right: 40px;

        .link-location {
          margin-bottom: 15px;
        }
      }

      .content-map {
        width: calc(100% - 300px);
      }
    }
  }

  @media screen and (max-width:845px) {
    .contact .container-map .info-box {
      width: 100%;
      padding-right: 0;
    }

    .contact .container-map .content-map {
      width: 100%;
      margin-top: 20px;
    }

    .container-search-box {
      max-width: 100%;
    }

    .contact .grid-two .grid {
      padding: 20px;
      margin-bottom: 15px;
    }
  }
</style>